@import "../../../../helper.scss";

div.view-matrices-container {
   position: absolute;
   z-index: 2;
   border-radius: 0;
   width: 100%;
   height: 100%;
   overflow-y: auto;

   transform: translateY(-100%);
   transition: transform 400ms easing("standard"), opacity 400ms easing("standard");
   opacity: 0;
   pointer-events: none;

   &.isOpen {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
   }

   div.title {
      margin-top: 24px;
      margin-bottom: 32px;
   }

   div.chart-wrapper {
      margin-bottom: 32px;
   }
}
