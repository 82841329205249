
div.graph-editor-sidebar {
   height: 100%;
   overflow: hidden;

   form {
      height: 100%;
      overflow: hidden;
   }

   div.editor-header {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      height: 48px;
      padding: 0 16px;

      button.close-editor {
         margin-left: 8px;

         @media screen and (min-width: 1201px) {
            display: none;
         }
      }
   }

   div.editor-content {
      height: calc(100% - 48px);
      overflow-y: auto;
      padding: 16px;

      div.editor-section {
         margin-bottom: 16px;
         margin-top: 16px;

         &:first-of-type {
            margin-top: 0;
         }
      }

      p.section-title {
         margin-bottom: 16px;
      }

      p.section-description {
         margin-bottom: 16px;
      }

      p.caption-text {
         line-height: 14px;
         opacity: 0.8;
      }
   }
}
